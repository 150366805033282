body {
  padding-top: 20px;
  padding-bottom: 40px;
}
table.table-hover.table-pointer tbody tr {
  cursor: pointer;
}
span.channels {
  margin-right: 1em;
}
